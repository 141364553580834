import './style.css';

const Privacy = () => {
    return (
        <div id="privacy">
            <h1>개인정보 처리방침</h1>

            <p>꼭코퍼레이션(이하 “회사”)은 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보 보호법』 등 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 회원의 권익 보호에 최선을 다하고 있습니다.</p>

            <p className="num">제1조 (수집하는 개인정보의 항목 및 수집방법)</p>
            <p>회사가 수집하는 개인정보 및 그 수집방법은 다음과 같습니다.</p>
            <ol>
                <li><p>회사의 "꼭이요” 어플리케이션 또는 그 어플리케이션을 통해서 제공되는 서비스(이하 “서비스”)에 관한 서비스 이용약관(이하 “약관”) 상의 회원이 되고자 하는 분(이하 “회원”)의 회원 가입 과정에서 아래와 같은 개인정보를 수집합니다.</p>
                    <p className="small">휴대전화번호, 생년월일, 성별</p></li>
                <li><p>서비스 이용 과정에서 아래와 같은 정보가 자동 생성 및 수집될 수 있습니다.</p>
                    <p className="small">단말기(모바일, 태블릿PC 등 각종 유무선 장치를 포함) IP 주소, MAC 주소, 접속 기록, 서비스 이용 기록, 불량 이용 기록, 단말기 정보(단말기 모델, 이동통신사 정보, 하드웨어 ID, 서비스 이용에 대한 기본 통계), 애플리케이션 설치 및 사용이력)</p></li>
                <li><p>회원이 서비스를 최초 이용할 때 회사는 아래와 같은 개인정보를 수집합니다.</p>
                    <p className="small">회원의 건강정보</p></li>
                <li><p>회원이 서비스를 최초 이용한 시점부터 회사는 아래와 같은 기능에 접근하여 개인정보를 수집합니다.</p>
                    <p className="small">회사는 서비스의 기능 제공을 위해서 회원의 단말기의 카메라, 마이크 및 사진첩 기능에 접근하여 회원의 사진, 영상, 음성 등 초상을 수집할 수 있습니다.</p></li>
            </ol>

            <p className="num">제2조 (개인정보의 수집/이용 목적)</p>
            <p>회사는 다음의 목적을 위해서 제1조의 개인정보를 수집/이용합니다.</p>
            <ol>
                <li><p>회원 가입 의사의 확인, 연령 확인, 회원 본인 확인, 회원 식별, 회원 가입 거절 사유 유무 확인, 회원탈퇴 의사의 확인 등 회원관리</p></li>
                <li><p>서비스 제공</p></li>
                <li><p>인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등</p></li>
                <li><p>법령 및 약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등 필요한 고지사항 전달, 분쟁해결, 분쟁조정을 위한 기록 보존, 민원처리 등 회원 보호 및 서비스 운영</p></li>
                <li><p>서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등</p></li>
                <li><p>보안, 프라이버시, 안전 측면에서 회원이 안심하고 이용할 수 있는 서비스 이용환경 구축</p></li>
                <li><p>단, 회원으로부터 이에 관한 별도의 동의를 받은 경우에 한하여, 이메일 주소, SNS 계정 정보를 회원에 대한 마케팅, 프로모션, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등을 위해서 사용</p></li>
            </ol>
            <p className="num">제3조 (개인정보 제3자 제공)</p>
            <p>회사는 회원의 사전 동의 없이는 회원의 개인정보를 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.</p>
            <ol>
                <li><p>회원이 서면 동의한 경우</p></li>
                <li><p>법령의 규정에 의하여 회사에 회원의 개인정보 제출의무가 발생한 경우</p></li>
            </ol>

            <p className="num">제4조 (개인정보 수집·이용·제공 거부 시 불이익)</p>
            <p>회원은 개인정보 수집·이용에 관한 동의를 거부할 권리가 있습니다. 다만, 회원이 개인정보의 수집·이용에 관한 동의를 거부할 경우 불가피하게 아래와 같은 불이익이 발생합니다.</p>
            <ol>
                <li><p>회원이 회원 가입 신청 시 회사가 수집·이용하고자 하는 개인정보의 수집·이용을 거부하는 경우에는 회원의 회원 가입이 불가능합니다.</p></li>
                <li><p>회원의 서비스 이용 과정에서 회사가 수집·이용 또는 접근하는 개인정보 또는 휴대전화 기능의 수집·이용 또는 접근을 거부하는 경우 회원에 대한 서비스의 제공이 제한될 수 있습니다.</p></li>
            </ol>

            <p className="num">제5조 (개인정보의 보유 및 이용기간)</p>
            <ol>
                <li><p>회사는 원칙적으로 개인정보(본 조에서는 민감정보를 포함합니다)를 회원탈퇴 시까지 보유합니다. 단, 개인정보 도용 등으로 인한 원치 않는 회원탈퇴 등에 대비하기 위해 회원탈퇴 요청 후 7일간 개인정보를 보존합니다.</p></li>
                <li>
                    <p>회원탈퇴 후에도 관계 법령의 규정에 의하여 보존할 필요가 있는 경우에는 일정 기간 동안 개인정보를 보관할 수 있습니다.</p>
                    <p className="small">『전자상거래 등에서 소비자 보호에 관한 법률』</p>
                    <p className="small">- 계약 또는 청약철회 등에 관한 기록: 5년 보관</p>
                    <p className="small">- 회원의 불만 또는 분쟁처리에 관한 기록: 3년 보관(다만, 민사, 형사, 행정상의 절차가 진행 중에 있는 경우에는 그 절차가 종료될 때까지)</p>
                    <p className="small">- 표시, 광고에 관한 기록: 6개월 보관</p>
                    <p className="small">『전자금융거래법』</p>
                    <p className="small">- 전자금융에 관한 기록: 5년 보관</p>
                    <p className="small">『통신비밀보호법』</p>
                    <p className="small">- 서비스 접속 일시, 접속 시간, 사용도수, 접속 정보를 감지한 기지국의 위치추적자료: 1년</p>
                    <p className="small">- 로그인 기록, 서비스에 접속하기 위하여 사용하는 휴대전화의 위치를 확인할 수 있는 접속지의 추적자료: 3개월</p>
                </li>
            </ol>

            <p className="num">제6조 (개인정보의 파기절차 및 방법)</p>

            <p>회사는 원칙적으로 개인정보 수집/이용 기간이 도과하면 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
            <ol>
                <li><p>파기 절차: 회사는 파기 사유가 발생한 개인정보를 선정하고 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</p></li>
                <li><p>파기 방법</p>
                    <p className="small">- 전자적 파일 형태인 경우: 복구 및 재생할 수 없는 기술적 방법으로 삭제</p>
                    <p className="small">- 출력물 그 밖의 기록매체인 경우: 분쇄 또는 소각</p></li>
            </ol>

            <p className="num">제7조 (개인정보의 별도보관)</p>
            <p>장기 미이용자의 경우 아래 각 호에 의해서 개인정보를 별도 보관 또는 파기합니다.</p>
            <ol>
                <li><p>회사는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』에 따라 회원이 회원 가입 시 선택한 기간(1년, 3년, 회원 탈퇴 시 중 하나) 동안 로그인을 하지 아니하는 등 서비스를 이용하지 않은 회원의 개인정보를 보호하기 위하여 해당 회원의 ID 및 개인정보를 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 분리하여 보관하거나 파기합니다.</p></li>
                <li><p>회사는 위 유효기간 만료 30일 전까지 기간 만료일, 개인정보가 별도 보관 또는 파기되는 사실, 해당 개인정보 항목을 전자우편, 서면, 모사전송, 전화 또는 이와 유사한 방법 중 어느 하나의 방법으로 이용자에게 알려드립니다.</p></li>
            </ol>
            <p className="num">제8조 (회원의 권리와 그 행사방법)</p>
            <ol>
                <li><p>회원은 회사에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.</p>
                    <p className="small">- 동의 철회</p>
                    <p className="small">- 개인 정보 열람 요구</p>
                    <p className="small">- 오류 등이 있을 경우 정정 요구</p>
                    <p className="small">- 삭제 요구</p>
                    <p className="small">- 처리정지 요구</p>
                </li>

                <li><p>회원은 전항에 따른 권리행사를 전자우편, 전화, 서면을 통하여 할 수 있으며 회사는 이에 대해 지체 없이 조치하고 처리결과를 전자우편, 문자 전송, 서면의 방법으로 통지합니다.</p></li>
                <li><p>회원이 개인정보의 오류에 대한 정정을 요청한 경우, 회사는 요청을 받은 때로부터 당해 정정을 완료하기 전까지 당해 개인정보를 이용하거나 제3자에게 제공하지 않습니다.</p></li>
                <li><p>회원이 개인정보의 삭제를 요청한 경우 제7조의 개인정보의 파기절차 및 방법에 따라 개인정보를 파기합니다.</p></li>
                <li><p>회원이 직접 또는 그로부터 위임을 받은 자(대리인)을 통하여 개인정보 조회, 수정, 삭제를 요청하실 수 있습니다. 대리인을 통하여 요청하는 경우, 『개인정보 보호법』 시행규칙 별지 제11호 서식에 따른 위임장을 회사에 제출하셔야 합니다.</p></li>
            </ol>
            <p className="num">제9조 (개인정보 자동 수집장치의 설치, 운영 및 거부에 관한 사항)</p>

            <ol>
                <li>
                    <p>회사는 개인정보를 수시로 저장하고 찾아내는 세션(session)을 사용합니다.</p>
                    <p className="small">- 세션(session): 서비스 운영에 이용되는 서버가 회원의 접속시간 동안에 개인정보를 서버에 저장하는 것을 의미합니다.</p>
                </li>
                <li><p>-세션은 회원이 설정한 환경을 유지하도록 함으로써 편리한 사용을 도우며, 방문 기록, 이용 형태를 알게 해줌으로써 이를 통한 최적화된 맞춤 서비스를 제공하고 서비스 개선의 척도로 활용됩니다.</p></li>
                <li><p>회원은 세션 설치에 대한 선택권을 가지고 있지 않으며, 로그인이 필요한 서비스 이용 시 서버에 자동으로 세션이 생성됩니다.</p></li>
                <li><p>회사는 회원이 스마트폰, 태블릿 PC의 사진첩, 카메라 및 마이크에 회사가 접근하는 것을 원하지 않을 경우 회원 스스로 접근을 차단할 수 있는 기술적 수단을 제공해 드립니다(즉, 회원이 휴대전화에서 “꼭이요” 앱의 사진첩, 카메라 및 마이크 기능에 대한 접근을 차단 또는 비활성화하면 됩니다).</p></li>
            </ol>
            <p className="num">제10조 (개인정보의 안전성 확보 조치)</p>
            <p>회사는 개인정보를 취급함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적, 관리적, 물리적 대책을 취하고 있습니다.</p>
            <ol>
                <li><p>관리적 조치: 내부관리계획 수립·시행, 정기적인 직원 교육</p></li>
                <li><p>기술적 조치: 개인정보처리시스템 등 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</p></li>
                <li><p>물리적 조치: 개인정보 보관 서버, 개인 정보 이용 단말기에 대한 접근 제한</p></li>
            </ol>
            <p className="num">제11조 (개인정보 보호책임자)</p>
            <p>회사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 다음과 같이 관련 부서 및 개인정보 관리책임자를 지정하고 있습니다. 회원은 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고할 수 있습니다. 회사는 회원들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>

            <p className="small">- 개인정보보호 담당부서 | 주식회사 꼭코퍼레이션 고객센터: 070-8064-0309 | 전자 우편주소: info@kkokiyo.app | 문의시간: 평일 오전 10시~오후 6시(점심시간 12:00~13:00, 토·일·공휴일 휴무)</p>
            <p className="small">- 개인정보 관리책임자 | 박성훈 | 전자우편주소: info@kkokiyo.app</p>

            <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
            <p className="small">- 개인정보침해신고센터 (http://www.1336.or.kr/ 국번없이 118)</p>
            <p className="small">- 개인정보분쟁조정위원회 (www.kopico.go.kr/ 국번없이 1833-6972)</p>
            <p className="small">- 대검찰청 사이버범죄수사단 (http://spo.go.kr/ 02-3480-3573)</p>
            <p className="small">- 경찰청 사이버안전국 (http://cyberbureau.police.go.kr/ 국번없이 182)</p>

            <p className="num">제12조 (고지)</p>

            <p>본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 14일 전에 공지사항을 통해 사전 공지를 할 것입니다.</p>

            <p>시행일자: 2024년 10월 2일</p>
        </div>
    );
};

export default Privacy;