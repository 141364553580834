import './style.css';
import Ex01G from '../../assets/ex01g.png';
import Ex02G from '../../assets/ex02g.png';
import Ex03G from '../../assets/ex03g.png';
import Ex04G from '../../assets/ex04g.png';
import Ex05G from '../../assets/ex05g.png';
import Ex06G from '../../assets/ex06g.png';
import Ex07G from '../../assets/ex07g.png';
import Ex08G from '../../assets/ex08g.png';
import Ex01W from '../../assets/ex01w.png';
import Ex02W from '../../assets/ex02w.png';
import Ex03W from '../../assets/ex03w.png';
import Ex04W from '../../assets/ex04w.png';
import Ex05W from '../../assets/ex05w.png';
import Ex06W from '../../assets/ex06w.png';
import Ex07W from '../../assets/ex07w.png';
import Ex08W from '../../assets/ex08w.png';

const P2 = () => {
    return (
        <div id="page2">
            <div className="ani-wrap">
                <div className="back">
                    <div className="wrap">
                        <div className="item"><img src={Ex01G} alt="" /></div>
                        <div className="item"><img src={Ex02G} alt="" /></div>
                        <div className="item"><img src={Ex03G} alt="" /></div>
                        <div className="item"><img src={Ex04G} alt="" /></div>
                        <div className="item"><img src={Ex05G} alt="" /></div>
                        <div className="item"><img src={Ex06G} alt="" /></div>
                        <div className="item"><img src={Ex07G} alt="" /></div>
                        <div className="item"><img src={Ex08G} alt="" /></div>
                        <div className="item"><img src={Ex01G} alt="" /></div>
                        <div className="item"><img src={Ex02G} alt="" /></div>
                        <div className="item"><img src={Ex03G} alt="" /></div>
                        <div className="item"><img src={Ex04G} alt="" /></div>
                        <div className="item"><img src={Ex05G} alt="" /></div>
                        <div className="item"><img src={Ex06G} alt="" /></div>
                        <div className="item"><img src={Ex07G} alt="" /></div>
                        <div className="item"><img src={Ex08G} alt="" /></div>
                        <div className="item"><img src={Ex01G} alt="" /></div>
                        <div className="item"><img src={Ex02G} alt="" /></div>
                        <div className="item"><img src={Ex03G} alt="" /></div>
                        <div className="item"><img src={Ex04G} alt="" /></div>
                        <div className="item"><img src={Ex05G} alt="" /></div>
                        <div className="item"><img src={Ex06G} alt="" /></div>
                        <div className="item"><img src={Ex07G} alt="" /></div>
                        <div className="item"><img src={Ex08G} alt="" /></div>
                    </div>
                </div>
                <div className="main">
                    <div className="wrap">
                        <div className="item"><img src={Ex01W} alt="" /></div>
                        <div className="item"><img src={Ex02W} alt="" /></div>
                        <div className="item"><img src={Ex03W} alt="" /></div>
                        <div className="item"><img src={Ex04W} alt="" /></div>
                        <div className="item"><img src={Ex05W} alt="" /></div>
                        <div className="item"><img src={Ex06W} alt="" /></div>
                        <div className="item"><img src={Ex07W} alt="" /></div>
                        <div className="item"><img src={Ex08W} alt="" /></div>
                        <div className="item"><img src={Ex01W} alt="" /></div>
                        <div className="item"><img src={Ex02W} alt="" /></div>
                        <div className="item"><img src={Ex03W} alt="" /></div>
                        <div className="item"><img src={Ex04W} alt="" /></div>
                        <div className="item"><img src={Ex05W} alt="" /></div>
                        <div className="item"><img src={Ex06W} alt="" /></div>
                        <div className="item"><img src={Ex07W} alt="" /></div>
                        <div className="item"><img src={Ex08W} alt="" /></div>
                    </div>
                </div>
            </div>
            <p><font color="#ff8b03">어떤 활동</font>이든<br />좋아요</p>
        </div>
    );
};

export default P2;