import { SectionsContainer, Section } from 'react-fullpage';
import Page1 from './pages/p1';
import Page2 from './pages/p2';
import Page3 from './pages/p3';
import Page4 from './pages/p4';
import Page5 from './pages/p5';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Privacy from './pages/privacy';
import Service from './pages/service';

const options = {
  activeClass: 'active',
  anchors: ['p1', 'p2', 'p3', 'p4', 'p5'],
  arrowNavigation: true,
  delay: 1000,
  navigation: true,
  scrollBar: false,
  sectionClassName: 'section'
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <>
            <div className="pc">
              <SectionsContainer {...options}>
                <Section>
                  <Page1 />
                </Section>
                <Section>
                  <Page2 />
                </Section>
                <Section>
                  <Page3 />
                </Section>
                <Section>
                  <Page4 />
                </Section>
                <Section>
                  <Page5 />
                </Section>
              </SectionsContainer >
            </div>
            <div className="mobile">
              <Page1 />
              <Page2 />
              <Page3 />
              <Page4 />
              <Page5 />
            </div>
          </>
        }></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/service" element={<Service />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;