import './style.css';
import LogoImage from '../../assets/p1_logo.png';

const P1 = () => {
    return (
        <div id="page1">
            <p>당신의 일상에</p>
            <img src={LogoImage} alt="logo" />
            <p>필요한<br/>건강한 기록</p>
        </div>
    );
};

export default P1;