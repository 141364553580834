import './style.css';
import Fire from '../../assets/p4_fire.png';

const P4 = () => {
    return (
        <div id="page4">
            <div className="fire-wrap">
                <img src={Fire} alt="" />
                <div className="balloon" id="b1">
                    <p>갓생 성공💕</p>
                </div>
                <div className="balloon" id="b2">
                    <p>역시 멋있어🥰</p>
                </div>
                <div className="balloon" id="b3">
                    <p>오운완 최고!👍🏻</p>
                </div>
            </div>
            <p>활동을 즐기는<br />친구를 <font color="#ff8b03">응원</font>해보세요</p>
        </div>
    );
};

export default P4;