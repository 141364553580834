import './style.css';
import Mobile from '../../assets/p3_mobile.png';

const P3 = () => {
    return (
        <div id="page3">
            <div className="wrap"><img src={Mobile} alt="" /><p>단 한장의 <font color="#ff8b03">사진</font>으로<br />기록해보세요</p></div>
        </div>
    );
};

export default P3;